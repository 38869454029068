import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { BTypography, Blink, FormAutocompleteField, NormalButton, PopTip, SortableList, UserAvatar, colors } from "bild-ui";
import { makeStyles } from "@mui/styles";
import { getEnumByKeyValue, uniqueArrayByObjectKey } from "bild-utils";
import { ENUMS } from "bild-data";
import NetworkPartnerDialog from "./components/networkPartnerDialog";

const useStyles = makeStyles({
  header: { background: colors.white },
  headerName: { padding: "1rem 0.5rem" },
  status: { fontWeight: "bold", textAlign: "center" },
  green: { color: colors.darkGreen },
  yellow: { color: colors.darkerYellow },
  red: { color: colors.darkRed },
  newButtonWrapper: { padding: "1rem", },
  filter: { minWidth: "10rem" },
  body: { padding: "0.5rem 0.5rem 2rem 0.5rem" },
});

export default function NetworkPartners({ partners, allUsers, updatePartner }) {
  const cls = useStyles();
  const [rows, setRows] = useState([]);
  const [newDialog, setNewDialog] = useState(false);
  const [coaches, setCoaches] = useState([]);
  const [showInactive, setShowInactive] = useState(false);
  const [coachFilter, setCoachFilter] = useState("");

  useEffect(()=>{
    let c = partners.map(x => x.primaryCoachUser);
    setCoaches(uniqueArrayByObjectKey(c, "id"));
  },[partners]);

  useEffect(()=>{
    setRows(
      partners
      .filter(x => {
        if (showInactive) {
          return x.isActive === false;
        } else {
          return x.isActive === true;
        }
      })
      .filter(x => {
        if (coachFilter) {
          return x.primaryCoachUser.id === coachFilter;
        } else {
          return true;
        }
      })
      .map(x => {
        return [
          x.name,
          [
            <BTypography sortval={x.status.statusId} className={`${cls.status} ${x.status.statusId === 1 ? cls.green : (x.status.statusId === 2 ? cls.yellow : (x.status.statusId === 3 ? cls.red : ""))}`} key={"00"}>
              <PopTip text={x.status ? (
                <Grid container >
                  <Grid item xs={12}><BTypography variant="h6">{x.status.noteSubject}</BTypography></Grid>
                  <Grid item xs={12}><BTypography variant="body1">{x.status.note}</BTypography></Grid>
                </Grid>
                ) : "~"}
              >
                <i className={"fad fa-circle fa-swap-opacity"} />
              </PopTip>
            </BTypography>
          ],
          x.status.statusDate ? x.status.statusDate : "~",
          [<UserAvatar size={25} src={x.primaryCoachUser.avatarURL} name={x.primaryCoachUser.name} key={x.primaryCoachUser.name} badgeSrc={x.primaryCoachUser.badgeURL} sortval={x.primaryCoachUser.name} />],
          x.mcceeLearnerCount,
          x.level1ShepherdingTotal,
          x.level2ShepherdingTotal,
          x.level3ShepherdingTotal,
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <NormalButton
                component={Blink}
                dst="network-partner"
                network_partner_id={x.id}
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
              >
                <i className="fas fa-chevron-right" />
              </NormalButton>
            </Grid>
          </Grid>
        ];
      })
    );

  },[partners, showInactive, coachFilter]);

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.header} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs className={cls.headerName}>
          <BTypography variant="h4">
            BILD Network Partners
          </BTypography>
        </Grid>
        <Grid item className={cls.filter}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInactive}
                onClick={()=>{setShowInactive(!showInactive)}}
              />
            }
            label={`Show Inactive (${partners ? partners.filter(x => x.isActive === false).length : ""})`}
          />
        </Grid>
        <Grid item className={cls.filter}>
          <FormAutocompleteField
            value={coachFilter}
            setValue={setCoachFilter}
            placeholder="Coach"
            items={coaches}
          />
        </Grid>
        <Grid item className={cls.newButtonWrapper}>
          <NormalButton
            variant="primary"
            color={colors.bildBlue}
            hoverColor={colors.darkBildBlue}
            labelColor={colors.white}
            onClick={()=>{setNewDialog(true);}}
            className={cls.newButton}
          >
            <i className="far fa-plus" />
          </NormalButton>
        </Grid>
      </Grid>
      <Grid item xs={12} className={cls.body}>
        <SortableList
          headers={["Partner", "Status", "Status Date", "Coach", "MCCEE #", "Level 1" , "Level 2" , "Level 3", ""]}
          sortableColumns={[0,1,2,3,4,5,6,7]}
          spacing={[2,1,2,2,1,1,1,1,1]}
          items={rows}
          itemType={"Partners"}
        />
      </Grid>
      <NetworkPartnerDialog open={newDialog} onClose={()=>{setNewDialog(false)}} allUsers={allUsers} updatePartner={updatePartner}/>
    </Grid>
  );
}
