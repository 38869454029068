import React from "react";
import { Route } from "react-router-dom";
import { checkManager, checkFundraiser, checkShowSearch, checkManageOrganizations, checkShowStaffSearch, checkCoach, checkBetaTester, checkAdmin } from "bild-data/user";

// LOADERS
import MessageLoader from "./message/messageLoader.js";
import EnrollmentStatementLoader from "./reports/enrollmentStatement/enrollmentStatementLoader.js";
import OrganizationStudentProgressLoader from "./reports/organizationStudentProgress/organizationStudentProgressLoader.js";
import OrganizationCourseProgressLoader from "./reports/organizationCourseProgress/organizationCourseProgressLoader.js";
import TotalStudentProgressLoader from "./reports/totalStudentProgress/totalStudentProgressLoader.js";
import ManageUserLoader from "./management/manageUsers/manageUserLoader.js";
import ManageUsersLoader from "./management/manageUsers/manageUsersLoader.js";
import ManageOrganizationsLoader from "./management/manageOrganizations/manageOrganizationsLoader.js";
import {
  ManageAllOrganizationsLoader,
  ManageOrganizationLoader,
  ManageAntiochOrganizationLoader,
  NetworkPartnersLoader,
  NetworkPartnerLoader,
  AvailableCertificatesLoader,
  UserCertificatesLoader,
  OrgUserCertificatesLoader,
  AllUserCertificatesLoader,
  UserCertificateValidationLoader,
  SystemRecentActivityLoader,
  OrganizationRecentActivityLoader,
  OrganizationWorldMapLoader,
  WorldMapLoader,
  StaffSearchLoader,
  BetaTesterLeaderboardLoader
} from "modules";
import FundraisingConstituentLoader from "./fundraising/fundraisingConstituentLoader.js";
import FundraisingItemsLoader from "./fundraising/fundraisingItemsLoader.js";
import FundraisingCompleteTaskLoader from "./fundraising/fundraisingCompleteTaskLoader.js";

const isAdmin = checkAdmin();
const isCoach = checkCoach();
const isManager = checkManager();
const isFundraiser = checkFundraiser();
const showSearch = checkShowSearch();
const isBetaTester = checkBetaTester();
const showStaffSearch = checkShowStaffSearch();
const showOrganizations = isManager || checkManageOrganizations();

////// MODULES ROUTES
export default [
  <Route path="/messages" element={<MessageLoader/>} key={"mod-0"} />,
  <Route path="/messages/*" element={<MessageLoader/>} key={"mod-0-0"} />,
  <Route path="/enrollments/:enrollment_id/statement" element={<EnrollmentStatementLoader/>} key={"mod-1"} />,
  <Route path="/reports/organization_student_progress/:organization_id" element={<OrganizationStudentProgressLoader/>} key={"mod-2"} />,
  <Route path="/reports/organization_student_progress" element={<OrganizationStudentProgressLoader/>} key={"mod-3"} />,
  <Route path="/reports/organization_module_progress/:organization_id" element={<OrganizationCourseProgressLoader/>} key={"mod-4"} />,
  <Route path="/reports/organization_module_progress" element={<OrganizationCourseProgressLoader/>} key={"mod-5"} />,
  <Route path="/reports/total_student_progress" element={<TotalStudentProgressLoader/>} key={"mod-6"} />,
  (showSearch || isBetaTester) && <Route path="/reports/system_recent_activity" element={<SystemRecentActivityLoader/>} key={"mod-7"} />,
  showSearch && <Route path="/certificates/available" element={<AvailableCertificatesLoader/>} key={"mod-8"} />,
  (showSearch || isBetaTester) && <Route path="/world_map" element={<WorldMapLoader/>} key={"mod-22"} />,
  showSearch && <Route path="/certificates/all_user_certificates" element={<AllUserCertificatesLoader/>} key={"mod-9"} />,
  isManager && <Route path="/certificates/organizations/:organization_id" element={<OrgUserCertificatesLoader/>} key={"mod-10"} />,
  <Route path="/certificate_validation" element={<UserCertificateValidationLoader/>} key={"mod-11"} />,
  <Route path="/certificates" element={<UserCertificatesLoader/>} key={"mod-12"} />,
  isManager && <Route path="/manage/users/:user_id" element={<ManageUserLoader/>} key={"mod-13"} />,
  isManager && <Route path="/manage/users" element={<ManageUsersLoader/>} key={"mod-14"} />,
  showSearch && <Route path="/manage/all_organizations" element={<ManageAllOrganizationsLoader/>} key={"mod-15"} />,
  isManager && <Route path="/manage/organizations/:organization_id/recent_activity" element={<OrganizationRecentActivityLoader/>} key={"mod-16b"} />,
  isManager && <Route path="/manage/organizations/:organization_id/world_map" element={<OrganizationWorldMapLoader/>} key={"mod-16c"} />,
  showOrganizations && <Route path="/manage/organizations/:organization_id" element={<ManageOrganizationLoader/>} key={"mod-16"} />,
  showOrganizations && <Route path="/manage/antioch/organizations/:organization_id" element={<ManageAntiochOrganizationLoader/>} key={"mod-16b"} />,
  showOrganizations && <Route path="/manage/organizations" element={<ManageOrganizationsLoader/>} key={"mod-17"} />,
  isFundraiser && <Route path="/fundraising/items/:fundraising_item_id" element={<FundraisingConstituentLoader/>} key={"mod-18"} />,
  isFundraiser && <Route path="/fundraising/tasks/:fundraising_task_id" element={<FundraisingCompleteTaskLoader/>} key={"mod-19"} />,
  isFundraiser && <Route path="/fundraising/tasks/:fundraising_task_id/complete" element={<FundraisingCompleteTaskLoader/>} key={"mod-20"} />,
  isFundraiser && <Route path="/fundraising" element={<FundraisingItemsLoader/>} key={"mod-21"} />,
  showStaffSearch && <Route path="/staff/search/:search_term" element={<StaffSearchLoader/>} key={"mod-23a"} />,
  showStaffSearch && <Route path="/staff/search" element={<StaffSearchLoader/>} key={"mod-23b"} />,
  isCoach && <Route path="/network_partners/:network_partner_id" element={<NetworkPartnerLoader/>} key={"mod-24"} />,
  isCoach && <Route path="/network_partners" element={<NetworkPartnersLoader/>} key={"mod-25"} />,
  (showSearch || isBetaTester) && <Route path="/bt_leaderboard" element={<BetaTesterLeaderboardLoader/>} key={"mod-25"} />,
];
