import React, { useEffect, useState } from "react";
import { loadScript } from "bild-utils";
import FileActionButton from "containers/folio/components/fileActionButton.js";
import "./vendor.css";

// TODO: replace this with list of allowed mime types from API
// Matches the list on https://intercom.help/bild-international/bild-cloud/students/acceptable-file-formats
const mimeTypes = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/pdf",
  "application/rtf",
  "text/rtf",
  "text/plain",
  "text/csv",
  "text/tab-separated-values",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "application/vnd.google-apps.document",
  "application/vnd.google-apps.spreadsheet",
  "application/vnd.google-apps.drawing"
];

// Scope to use when accessing user's drive.
const scope = "https://www.googleapis.com/auth/drive.readonly";

// BILD Google Drive File Picker with Google Identity Services Auth
// https://developers.google.com/drive/picker/guides/sample
function GoogleDriveFilePicker({ contextRoot, autoLoad, onUploadFiles }) {
  const [tokenClient, setTokenClient] = useState(null); // Store the user's google identity service client
  const [accessToken, setAccessToken] = useState(null); // Store the user's oauthToken once it's generated
  const [gisLoaded, setGisLoaded] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);

  // Initial load of scripts
  useEffect(() => {
    loadScript("https://accounts.google.com/gsi/client", "googleidentityjs", [], onGisLoad);
    loadScript("https://apis.google.com/js/api.js", "googledrivejs", [], onApiLoad);
  }, [contextRoot]);

  // Open picker automatically if fully loaded and autoLoad is true
  useEffect(() => {
    if (gisLoaded && apiLoaded && autoLoad) {
      getPicker();
    }
  }, [gisLoaded, apiLoaded, autoLoad]);

  // Initialize Google Identity Services
  function onGisLoad() {
    setTokenClient(
      window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
        scope: scope,
        callback: "" // Handle in the picker creater
      })
    );
    setGisLoaded(true);
  }

  // Initialize Google API (Drive)
  function onApiLoad() {
    window.gapi.load("picker", () => {
      setApiLoaded(true);
    });
  }

  // Create Drive File Picker with optional overriding accessToken
  function createPicker(defaultOauthToken) {
    const oauthToken = accessToken ? accessToken : defaultOauthToken;
    const googleViewId = window.google.picker.ViewId["DOCS"];
    const view = new window.google.picker.DocsView(googleViewId);
    view.setIncludeFolders(true);

    // Allowed Mime Types
    view.setMimeTypes(mimeTypes.join(","));

    const origin = window.location.protocol + "//" + process.env.REACT_APP_ORIGIN;
    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(process.env.REACT_APP_GOOGLE_DRIVE_KEY)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setOrigin(origin)
      .setCallback(change => {
        onChange(change, oauthToken);
      })
      .build();
    picker.setVisible(true);
  }

  // Decide if we need to build a picker with new or existing auth
  function getPicker() {
    tokenClient.callback = async response => {
      if (response.error !== undefined) {
        throw response;
      }
      setAccessToken(response.access_token);
      createPicker(response.access_token);
    };

    if (accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      // tokenClient.requestAccessToken({prompt: ''});
      createPicker();
    }
  }

  // Handle changes to the file picker state
  function onChange(change, oauthToken) {
    // Currently, we only care if they picked a file/files
    // options are PICKED, CANCEL, "loaded"
    if (change.action === window.google.picker.Action.PICKED) {
      onSuccess(change.docs, oauthToken);
    }
  }

  // Handle "Submit" button click from picker. Prepare and send to our backend
  function onSuccess(docs, oauthToken) {
    let files = [];

    // Grab data from docs array and fit into our file object structure
    for (let i = 0; i < docs.length; i++) {
      let d = docs[i];
      let file = {
        name: d.name,
        contentType: d.mimeType,
        fileSizeBytes: d.sizeBytes,
        googleFileId: d.id,
        googleAuthToken: oauthToken,
        googleExport: d.mimeType.includes("google-apps"),
        folderId: contextRoot ? contextRoot.id : null
      };
      files.push(file);
    }

    onUploadFiles(files, contextRoot);
  }

  return <span>{gisLoaded && apiLoaded && <FileActionButton onClick={getPicker} contextRoot={contextRoot} variant="add-from-google" />}</span>;
}

export default GoogleDriveFilePicker;
