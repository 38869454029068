import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { colors, BTypography, TreeDiagram, NormalButton, PopTip, UserAvatar, SupportDialog } from "bild-ui";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import ShepherdingOverview from "equip/review/shepherdingOverview/shepherdingOverview";
import { ENUMS } from "bild-data";
import { getEnumByKeyValue } from "bild-utils";
import NetworkPartnerOrganizations from "./components/networkPartnerOrganizations";
import NetworkPartnerStatuses from "./components/networkPartnerStatuses";
import NetworkPartnerStatusDialog from "./components/networkPartnerStatusDialog";
import NetworkPartnerDialog from "./components/networkPartnerDialog";
import Folio from "containers/folio/folio";

const useStyles = makeStyles((theme) =>
  createStyles({
    empty: { padding: "1rem", color: colors.darkGray },
    header: { padding: "1rem 1rem", background: colors.white, position: "sticky", top: 0, zIndex: 1000, maxHeight: "20vh", overflow: "auto" },
    lastSeen: { fontStyle: "italic", padding: "0 0.5rem" },
    emailWrapper: { padding: "0.5rem 0" },
    email: { padding: "0.25rem 0.5rem", borderRadius: "0.2rem", border: `0.1rem solid ${colors.lightGray}`},
    headerRight: { textAlign: "right", },
    headerStatus: {
      border: `0.2rem solid ${colors.lightBlue}`,
      borderRadius: "0.4rem",
      padding: "1rem"
    },
    headerSubject: {
      padding: "0.25rem",
      textAlign: "left",
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "scroll",
      textOverflow: "ellipsis",
    },
    headerName: { paddingRight: "1rem" },
    body: { paddingBottom: "2rem" },
    sectionWrapper: { 
      padding: "0.5rem 0.5rem 0 0",
      [theme.breakpoints.down('md')]: {
        padding: "0.25rem !important"
      },
    },
    sectionWrapperLeft: { paddingLeft: "0.5rem" },
    section: { padding: "0.5rem", background: colors.white },
    sectionHeader: { padding: "0.5rem" },
    sectionInner: { minHeight: "20rem", maxHeight: "25rem", overflowY: "auto", },
    sectionDiagram: { maxHeight: "20rem !important", overflow: "hidden !important" },
    item: {
      backgroundColor: colors.white,
      border: "1px solid",
      borderRadius: `4px`,
      padding: "1rem",
      height: "100%"
    },
    status: { fontWeight: "bold", textAlign: "center" },
    greenStatus: { color: colors.darkGreen },
    yellowStatus: { color: colors.darkerYellow },
    redStatus: { color: colors.darkRed },
    label: { textAlign: "right", paddingRight: "1rem" },
    input: {
      width: "100%",
      minHeight: "3.5rem",
      padding: "0 1rem",
      background: colors.white,
      border: `1px solid ${colors.black}`,
      borderRadius: "4px"
    },
  }));

export default function NetworkPartner({ partner, allOrganizations, allUsers, disk, onCreateFolder, onUploadFiles, onDownloadItem, onPreviewIntent, previewDocId, previewDocLoading, previewFile, updatePartner }) {
  const theme = useTheme();
  const cls = useStyles(theme);
  const [status, setStatus] = useState(partner.status ? partner.status : {});
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDetails, setStatusDetails] = useState(null);
  const [partnerDialog, setPartnerDialog] = useState(false);
  const [deleteFileRequest, setDeleteFileRequest] = useState(null);
  
  useEffect(()=>{
    if (partner.status) {
      setStatus(partner.status);
    }
  },[partner])

  function _getStatusClass(s) {
    return s.statusId === 1 ? cls.greenStatus : (s.statusId === 2 ? cls.yellowStatus : (s.statusId === 3 ? cls.redStatus : ""));
  }

  function _openStatusDialog(s) {
    setStatusDialog(true);
    setStatusDetails(s);
  }

  function _closeStatusDialog() {
    setStatusDialog(false);
    setStatusDetails(null);
  }

  return (
    <Grid container>
      <Grid container item xs={12} className={cls.header} justifyContent="space-between" alignItems="center">
        <Grid container item sm={8} xs={12} justifyContent="flex-start" alignItems="center">
          <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
            <Grid item className={cls.headerName}>
              <BTypography variant="h3">{partner.name}</BTypography>
            </Grid>
            <Grid item>
              <NormalButton
                variant="primary"
                color={colors.bildBlue}
                hoverColor={colors.darkBildBlue}
                labelColor={colors.white}
                onClick={()=>{setPartnerDialog(true);}}
                className={cls.newButton}
              >
                <i className="far fa-edit" />
              </NormalButton>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent={"flex-start"} alignItems={"center"}>
            {partner.keyLeaderUser && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <BTypography variant="body1"><b>Key Leader:</b> &nbsp;</BTypography>
                </Grid>
                <Grid item>
                  <UserAvatar size={25} src={partner.keyLeaderUser.avatarURL} name={partner.keyLeaderUser.name} badgeSrc={partner.keyLeaderUser.badgeURL} nameVariant="body1" />
                </Grid>
              </Grid>
            )}
            {partner.primaryCoachUser && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <BTypography variant="body1"><b>Primary Coach:</b> &nbsp;</BTypography>
                </Grid>
                <Grid item>
                  <UserAvatar size={25} src={partner.primaryCoachUser.avatarURL} name={partner.primaryCoachUser.name} badgeSrc={partner.primaryCoachUser.badgeURL} nameVariant="body1" />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={6} justifyContent="flex-start" alignItems="center">
            {partner.leaderNote && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <BTypography variant="body1"><b>Leader Note:</b> &nbsp;</BTypography>
                </Grid>
                <Grid item xs className={cls.headerSubject}>
                  {partner.leaderNote}
                </Grid>
              </Grid>
            )}
            {partner.generalNote && (
              <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <BTypography variant="body1"><b>General:</b> &nbsp;</BTypography>
                </Grid>
                <Grid item xs className={cls.headerSubject}>
                  {partner.generalNote}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item sm={4} xs={12} justifyContent="flex-end" alignItems="flex-end" className={cls.headerRight}>
          <Grid container item xs={12} className={cls.headerStatus} justifyContent="flex-end" alignItems="flex-end">
            {status && status.statusId && (
              <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <BTypography className={`${cls.status} ${_getStatusClass(status)}`} variant="h3">
                    <PopTip text={getEnumByKeyValue(ENUMS.NETWORK_PARTNERS.STATUSES, "ID", status.statusId, "NAME")}>
                      <i className={"fad fa-circle fa-swap-opacity"} />
                    </PopTip>
                  </BTypography>
                </Grid>
                <Grid item xs className={cls.headerSubject}>
                  <BTypography variant="body1">
                    {status.noteSubject}
                  </BTypography>
                </Grid>
                <Grid item>
                  <BTypography variant="body1">
                    {status.statusDate}
                  </BTypography>
                </Grid>
                <Grid item>
                  <NormalButton
                    variant="micro"
                    color={colors.offWhite}
                    borderColor={colors.bildBlue}
                    hoverColor={colors.darkBildBlue}
                    labelColor={colors.darkBildBlue}
                    className={cls.detailButton}
                    onClick={()=>{
                      _openStatusDialog(status);
                    }}
                  >
                    <i className="far fa-chevron-right" />
                  </NormalButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={cls.body}>
        <Grid container item md={8} sm={12} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          <Grid item xs={12} className={cls.section}>
            <Grid item xs={12} className={cls.sectionInner}>
              {partner.shepherdingOverviews && (
                <ShepherdingOverview user={partner.primaryCoachUser} data={partner} dashboardView />
              )}
              {!partner.shepherdingOverviews && (
                <BTypography>No MCCEE Participants in this Partner's Organizations.</BTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={4} sm={12} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperRight}`}>
          <Grid item xs={12} className={cls.section}>
            <NetworkPartnerStatuses partner={partner} statuses={partner.allStatuses} updatePartner={updatePartner}/>
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          <Grid item xs={12} className={cls.section}>
            <Grid item xs={12} className={`${cls.sectionInner} ${cls.sectionDiagram}`}>
              {partner.organizationRelationships && (
                <TreeDiagram data={partner.organizationRelationships} defaultScale={partner.organizationRelationships.length > 5 ? 5 : (partner.organizationRelationships.length > 3 ? 4 : 3)} maxContainerHeight={"35vh"} />
              )}
              {!partner.organizationRelationships && (
                <BTypography>No Network.</BTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={3} md={4} sm={12} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperLeft}`}>
          <Grid item xs={12} className={cls.section}>
            <NetworkPartnerOrganizations partner={partner} organizations={partner.organizations} allOrganizations={allOrganizations} updatePartner={updatePartner}/>
          </Grid>
        </Grid>
        <Grid container item lg={9} md={8} sm={12} xs={12} className={`${cls.sectionWrapper} ${cls.sectionWrapperRight}`}>
          {disk && (
            <Folio
              diskArrays={disk}
              onCreateFolder={onCreateFolder}
              onUploadFiles={onUploadFiles}
              onPreviewIntent={onPreviewIntent}
              onDownloadItem={onDownloadItem}
              onDeleteItem={(x)=>{setDeleteFileRequest(x)}}
              previewDocId={previewDocId}
              previewDocLoading={previewDocLoading}
              previewFile={previewFile}
              rootBreadcrumbTitle="Network Partner Files"
              fullWidth
            />
          )}
        </Grid>
      </Grid>
      <NetworkPartnerStatusDialog open={statusDialog} onClose={_closeStatusDialog} status={statusDetails} updatePartner={updatePartner} />
      <NetworkPartnerDialog
        open={partnerDialog}
        onClose={()=>{setPartnerDialog(false)}}
        partner={partner}
        allUsers={allUsers}
        updatePartner={updatePartner}
      />
      <SupportDialog
        open={deleteFileRequest ? true : false}
        closeDialog={() => {
          setDeleteFileRequest(null);
        }}
        options={[`I would like to remove this file id: '${deleteFileRequest ? deleteFileRequest.id : ""}' with name: '${deleteFileRequest ? deleteFileRequest.name : ""}' for partner id '${partner.id}'.`]}
      />
    </Grid>
  );
}
