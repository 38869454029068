import React, { useState, useEffect } from "react";
import { managerData, partnersData } from "bild-data";
import { setPageTitle } from "bild-utils";
import { LoadingScreen } from "bild-ui";
import NetworkPartners from "./networkPartners";
import { toaster } from "presentational/toasts/toasts";

export default function NetworkPartnersLoader({}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [partners, setPartners] = useState([]);
  const [allUsers, setAllUsers] = useState(null);

  useEffect(() => {
    setPageTitle("Network Partners");

    partnersData.getAllNetworkPartners(_setData);
    managerData.getManagedUsers(
      x => {
        setAllUsers(x.users);
      },
      e => {
        setError(e.response.data.error);
      }
    );
  }, []);

  function _setData(d) {
    setPartners(d.partners);
    setLoading(false);
  }

  function updatePartner(data) {
    partnersData.createUpdateNetworkPartner(data, ()=>{
      partnersData.getAllNetworkPartners(_setData);
      toaster.success("Partner Created!");
    })
  }

  if ((loading) || error) return <LoadingScreen error={error} />;
  return (
    <NetworkPartners partners={partners} allUsers={allUsers} updatePartner={updatePartner} />
  );
}
